type TinitialValues = {
  allCities: [];
  allAreas: [];
  languages: [];
  educationTypes: [];
  schoolYears: [];
  schoolTypes: [];
  singleUser: any;
};

export const initialValues: TinitialValues = {
  allCities: [],
  allAreas: [],
  languages: [],
  educationTypes: [],
  schoolYears: [],
  schoolTypes: [],
  singleUser: {},
};
