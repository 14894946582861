import { createAsyncThunk } from "@reduxjs/toolkit";
import GENERALAPI from "./api";

const doGetALLCITIES = createAsyncThunk<any, any, any>(
  "general/getAllCities",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getCities();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetAllAreas = createAsyncThunk<any, any, any>(
  "general/getAllAreas",
  async ({ cityid }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getAreas(cityid);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetLanguages = createAsyncThunk<any, any, any>(
  "general/getLanguages",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getLanguages();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetEducationTypes = createAsyncThunk<any, any, any>(
  "general/getEducationTypes",
  async ({ school_year_id }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getEducationTypes(school_year_id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetSchoolYears = createAsyncThunk<any, any, any>(
  "general/getSchoolYears",
  async ({ teacherId }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getSchoolYears(teacherId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetSchoolTypes = createAsyncThunk<any, any, any>(
  "general/getSchoolTypes",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getSchoolTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dogetSingleUser = createAsyncThunk<any, any, any>(
  "general/getSingleUser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getSingleUser(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doUpdateUser = createAsyncThunk<any, any, any>(
  "admin/doUpdateUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await GENERALAPI.updateUser(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const thunks = {
  doGetALLCITIES,
  doGetAllAreas,
  doGetLanguages,
  doGetEducationTypes,
  doGetSchoolYears,
  doGetSchoolTypes,
  dogetSingleUser,
  doUpdateUser,
};

export default thunks;
