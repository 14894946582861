import { getCookie, deleteCookie } from "cookies-next";
import axios from "axios";

// https://backend.edu-downtown.com=> old
// https://server.edu-downtown.com => new
// https://testfront.edu-downtown.com

export const mainURL = "https://backend.edu-downtown.com";
export const baseURL = "https://backend.edu-downtown.com/api/v1/user";
export const ADMINURL = "https://backend.edu-downtown.com/api/v1/admin";

export const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

// Request interceptor for user APIs
api.interceptors.request.use(async (config) => {
  const token = getCookie("Education_User_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor to handle 401 status
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Clear the user token cookie if 401 error occurs
      deleteCookie("Education_User_token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const adminApi = axios.create({
  baseURL: ADMINURL,
  headers: {
    Accept: "application/json",
  },
});

// Request interceptor for admin APIs
adminApi.interceptors.request.use(async (config) => {
  const token = getCookie("Education_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor to handle 401 status
adminApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      const requestUrl = error.config?.url; // Get the request URL from the config
      if (!requestUrl.includes("my-permissions")) {
        deleteCookie("Education_token");
        window.location.href = "/adminLogin";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
